/* import Poppins from google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*{
  margin:0;
  padding:0;
  font-family: 'Roboto', sans-serif;
}

h1,h2,h3,h4{
  font-family: 'Poppins', sans-serif;
}